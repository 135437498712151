.scroller{
  width: 1450px;
 height: 230px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

  .scroller::-webkit-scrollbar {
    width: 1px;
    height: 10px;
  }
   
  .scroller::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }
   
  .scroller::-webkit-scrollbar-thumb {
    background-color: $primary;
    outline: 1px solid $primary;
    border-radius: 10px;
  }