.wrap,
.box {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.box img {
  display: block;
  position: absolute;
}

.drops {
  animation: drops 8s ease-in both;
}

@keyframes drops {
  from {
    top: -10vh;
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  to {
    opacity: 0;
    top: 100vh;
  }
}

.foot {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.bj {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.redPacket {
  position: relative;
  bottom: 0;
  left: 0;
  width: 60%;
  height: 50px;
  transition: left 0.2s linear;
}
