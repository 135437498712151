$primary: #76C14E;
$secondary: #233F87;
$green: #1e5a50;
$white: #f4f3f2;
$hover-green: #cef0ea;
$bg: #fff;
$txt: #000;
$txtRevert: #000;
$altTxt: #f7f1e1;
$gray: #000;
