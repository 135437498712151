.ant-modal {
  width: min-content !important;
  .ant-modal-content {
    background-color: rgba(255, 255, 255, 0.95);
  }
}
.loading-icon {
  display: flex;
  justify-content: center;
}

.ant-modal .anticon svg {
  width: 30px;
  height: 30px;
  color: $txt;
  margin-top: 20px;
  margin-right: 20px;
}

.ant-modal-close {
  z-index: 30;
}

.ant-modal-close:hover {
  border: 0px;
}

.ant-picker-input input {
  font-size: 11px;
}

.ant-table-thead > tr > th {
  font-weight: bold;
  font-size: 12px;
}
.ant-table-content {
  font-size: 12px;
}

.ant-modal {
  border-radius: 10px !important;
}

.ant-modal-content {
  width: 450px;
  height: fit-content;
}

.ant-modal-footer {
  padding: 15px 16px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.ant-modal .anticon svg {
  width: 20px;
  height: 20px;
  margin-top: 0px;
  margin-right: 12px;
  margin-bottom: 6px;
}

.ant-modal-title {
  text-align: center;
  font-weight: 700;
}

// Select Dropdown
select {
  appearance: none;
}
// Custom Dropdown
select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.ant-btn-primary {
  color: #fff;
  border-color: #76c14e !important;
  background: #76c14e !important;
}

// Select
select {
  appearance: none;
}

// Selection Items
.ant-select-item:hover,
.ant-select-item:focus {
  border: 2px solid #76c14e;
  border-radius: 3px;
}

.ant-select-item,
.ant-select-item-option-selected,
.ant-select-item-option-active,
.ant-select-item-option-content {
  background-color: #fff !important;
}

.ant-select-selection-item,
.ant-select-selection-item:active,
.ant-select-item {
  background-color: #fff;
  overflow: hidden;
  font-size: 12px;
  font-weight: bold;
}

.ant-select-selection-item {
  display: flex !important;
  align-items: center !important;
  margin: 6px auto !important;
  border-right: 1px solid #d9d9d9 !important;
}

.ant-select-selector {
  border-right-width: 0 !important;
  border-width: 0 !important;
}

.ant-select-selector:focus-within,
.ant-select-selector:focus-visible {
  box-shadow: none !important;
  outline: 0 !important;
}

.ant-dropdown-menu-item {
  font-size: 12px;
  font-weight: bold;
  min-width: 150px;
  &:hover {
    background-color: #76c14e;
    color: $white;
  }
}

@media only screen and (max-width: 640px) {
  .ant-modal-content {
    width: 350px;
    height: fit-content;
  }
}
