$titleFmaily: "Open Sans";
$subTitleFmaily: "Open Sans";
$txtFamily: "Open Sans";
$chineseFamily: "Noto Sans TC";
$scFamily: "Noto Sans SC";

// Size
$tiny: 10px;
$small: 11px;
$medium: 13px;
$large: 14px;
$xlarge: 16px;
$xxlarge: 18px;
$xxxlarge: 60px;

h1,
h2,
h3,
h4,
h5 {
  color: $txt;
  font-weight: 700;
}

h1 {
  font-family: $chineseFamily;
  font-family: $titleFmaily;
  font-family: $scFamily;
}

h2 {
  font-family: $chineseFamily;
  font-family: $subTitleFmaily;
  font-family: $scFamily;
}

h3,
h4 {
  font-family: $chineseFamily;
  font-family: $subTitleFmaily;
  font-family: $scFamily;
  letter-spacing: 3px;
  font-weight: 700;
}

h4 {
  font-size: 22px;
  text-transform: uppercase;
}

p,
li,
a,
pre {
  font-family: $chineseFamily;
  font-family: $txtFamily;
  font-family: $scFamily;
  color: black;
  font-weight: 700;
  margin-bottom: 0;
}
